import axios from 'axios';
import getToken from '../../utils/getToken'

export default class ProductController{
  static removeProduct(id, _success, _error){

  }
  static createProduct(data, _success, _error){

  }
  static updateProduct(data, _success, _error){

  }
  static getProduct(query, _success, _error){
    const token = getToken();
    axios({
      method : "GET",
      url : `/api/product/${query.id}`,
      /*headers: {
        Authorization: 'Bearer ' + token
      },*/
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
  static updateProductView(query, _success, _error){
    const token = getToken();
    axios({
      method: "GET",
      url : `/api/product/${query.id}/view`,
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
  static getProducts(query, _success, _error){

    const token = getToken();
    axios({
      method: "POST",
      url:`/api/product`,
      /*headers: {
        Authorization: 'Bearer ' + token
      },*/
      data : query
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
  static getBrands(query, _success, _error){
    const token = getToken();
    axios({
      method: 'GET',
      url:  '/api/brand',
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
  static getBrandsDetail(query, _success, _error){
    const token = getToken();
    axios({
      method: 'GET',
      url:  '/api/brand/alias',
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
}
