import React, {useEffect, useState, useRef} from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import WineBarTwoToneIcon from '@mui/icons-material/WineBarTwoTone';
import LiquorIcon from '@mui/icons-material/Liquor';
import TagIcon from '@mui/icons-material/Tag';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

import {
  Grid,
  Button,
  Container,
  Stack,
  Chip,
  InputAdornment
} from '@mui/material';

import MenuItem from '../../components/menu/MenuItem';
import Footer from '../../components/common/Footer';

import ProductController from '../../apis/products/ProductController';

import { blue, amber, orange, deepOrange } from '@mui/material/colors';

const drawerWidth = 240;

const StyledTextField = styled(TextField)`
    fieldset {
        border-radius: 28px;
        border-color: #ffffff;
        color: #ffffff;
      },
    label {
        color:#ffffff;
    },
    input {
      color: #ffffff;
    },
    .Mui-focused fieldset {
        border-color: #ffffff;
    }
`;

const CATEGOTY_LIST = [
  {
    key:"SCOTCH_SINGLE",
    title:"스카치 싱글몰트",
    url: "/"
  },{
    key:"SCOTCH_ISLAY_SINGLE",
    title:"스카치 아일라",
    url: "/"
  },{
    key:"SCOTCH_BLENDED",
    title:"스카치 블렌디드",
    url: "/"
  },{
    key:"IRISH",
    title:"아이리시",
    url: "/"
  },{
    key:"SAN",
    title:"독립병입",
    url: "/"
  },{
    key:"DIAGIRO",
    title:"디아지로 SR",
    url: "/"
  },{
    key:"AMERICAN",
    title:"아메리칸 위스키(버번,라이)",
    url: "/"
  },{
    key:"BRENDY",
    title:"브랜디(꼬냑, 깔바도스 등)",
    url: "/"
  },{
    key:"ETC",
    title:"기타지역(대만 인도 등)",
    url: "/"
  },{
    key:"LIQUOR",
    title:"리큐르",
    url: "/"
  },{
    key:"OTHER",
    title:"기타증류주(진,럼,보드카,데킬라)",
    url: "/"
  },{
    key:"COCKTAIL",
    title:"칵테일",
    url: "/"
  }
]

function MenuPage(props) {

  const [isProductLoading, setIsProductLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const getProductSuccess = (response) => {

    if(response.status === 200){
      setProducts(response.data.products);
    }
    setIsProductLoading(false);
  };

  const getProductError = (error) => {
    setIsProductLoading(false);
  };

  useEffect(() => {
    if(isProductLoading){
      ProductController.getProducts({}, getProductSuccess, getProductError);
    }
  }, [isProductLoading]);

  return (
    <Box sx={{ flexGrow: 1, width: "100%", height:"100%", position:'relative', boxSizing:'border-box', overflow:'scroll' }}>
      <Box sx={{width:'100%', position:'relative', minHeight:{xs:'60vh',md:'40vh'}, padding:'72px 0px'}}>
        <img src="/images/menu_image.jpg"
          style={{position:'absolute', width:'100%', height:'100%', top:'0px', left:'0px', right:'0px', bottom:'0px', objectFit:'cover'}}/>
        <Box sx={{
          position:'absolute',
          width:'100%',
          height:'100%',
          top:'0px', left:'0px', right:'0px', bottom:'0px',
          background:'linear-gradient(180deg, rgba(0,0,0,.3), #ffffff)'}}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{width:'100%', height:'100%', position:'relative', padding:'0px 24px', boxSizing:'border-box'}}
            spacing={2}>
            <Typography variant="h6" element="p" sx={{
              fontFamily: 'Gowun Bold',
              textAlign:"center", fontSize:'32px', fontWeight:900, color:'#ffffff'}}>
              좋아하는 위스키를 검색해보세요
            </Typography>
            <Box sx={{ width:'100%', maxWidth:'720px'}}>
              <StyledTextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{color:'#ffffff'}}/>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width:'100%',
                  borderColor:'#ffffff',
                  borderRadius:'24px',
                  color:"#ffffff"
                }}
                fullWidth
                label="검색어"
                id="fullWidth" />
            </Box>
            <Box sx={{ width:'100%', maxWidth:'720px'}}>
              {CATEGOTY_LIST.map((el) => (
                <Button
                  key={el.key}
                  variant="outlined"
                  sx={{
                    fontFamily: 'Gowun Regular',
                    fontWeight:900, borderRadius:'24px', m:"4px", backgroundColor:'#ffffff', color:'#222', border:'0px'}}>{el.title}</Button>
              ))}
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box sx={{width:{md : '100%', sm : '100%'}, backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
        <Box sx={{position:'relative', width:'100%'}}>
          <Typography variant="h6" element="p" sx={{
            fontFamily: 'Gowun Bold',
            textAlign:"center", fontSize:'32px', fontWeight:900, color:'#222222'}}>
            Today's PICK
          </Typography>
        </Box>
      </Box>
      <Box sx={{width:{md : '100%', sm : '100%'}, backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
        <Box sx={{position:'relative', width:'100%'}}>
          <Grid
            container
            spacing={2}
            sx={{width:'100%'}}>
            <MenuItem/>
          </Grid>
        </Box>
      </Box>
      <Box sx={{width:{md : '100%', sm : '100%'}, backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
        <Box sx={{position:'relative', width:'100%'}}>
          <Typography variant="h6" element="p" sx={{
            fontFamily: 'Gowun Bold',
            textAlign:"center", fontSize:'32px', fontWeight:900, color:'#222222'}}>
            인기 위스키
          </Typography>
        </Box>
      </Box>
      <Box sx={{width:{md : '100%', sm : '100%'}, backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
        <Box sx={{position:'relative', width:'100%'}}>
          <Grid
            container
            spacing={2}
            sx={{width:'100%'}}>
            {products && products.map((el) => (
              <MenuItem
                item={el}/>
            ))}
          </Grid>
        </Box>
      </Box>
      {/*<Box sx={{width:{md : '100%', sm : '100%'}, backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
        <Box sx={{position:'relative', width:'100%'}}>
          <Grid
            container
            spacing={2}
            sx={{width:'100%'}}>
            <Grid item md={12}>
            </Grid>
            <Grid item md={12}>
              <Typography variant="h6" element="p" sx={{textAlign:"left", mb:'12px', fontSize:'32px', fontWeight:900}}>
                지역별 위스키
              </Typography>
              <Typography variant="h6" element="p" sx={{textAlign:"left", mb:'24px', fontSize:'18px', fontWeight:700, color:'#555'}}>
                위치에 따라 기후와 환경이 다르기 때문에, 특징이 모두 다릅니다. 오드비에서 다양한 지역의 위스키를 맛보시기를 바랍니다.
              </Typography>
            </Grid>
            <Grid item md={3} lg={2}>
              <Box sx={{position:'relative', width:'100%', paddingBottom:'100%', mb:'12px'}}>
                <img src="/images/scotland.jpg" style={{
                  position:'absolute',
                  top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%',
                  borderRadius:'16px', objectFit:'cover'
                }}/>
              </Box>
              <Typography element="p" sx={{textAlign:"left", fontWeight:900, mb:'0px', fontSize:'20px'}}>
                스카치 위스키
              </Typography>
              <Typography element="p" sx={{textAlign:"left", mb:'24px', fontSize:'14px'}}>
                스코틀랜드 (Scotland)
              </Typography>
            </Grid>
            <Grid item md={3} lg={2}>
              <Box sx={{position:'relative', width:'100%', paddingBottom:'100%', mb:'12px'}}>
                <img src="/images/ireland.jpg" style={{
                  position:'absolute',
                  top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%',
                  borderRadius:'16px', objectFit:'cover'
                }}/>
              </Box>
              <Typography element="p" sx={{textAlign:"left", fontWeight:900, mb:'0px', fontSize:'20px'}}>
                아이리쉬 위스키
              </Typography>
              <Typography element="p" sx={{textAlign:"left", mb:'24px', fontSize:'14px'}}>
                아일랜드 (Ireland)
              </Typography>
            </Grid>
            <Grid item md={3} lg={2}>
              <Box sx={{position:'relative', width:'100%', paddingBottom:'100%', mb:'12px'}}>
                <img src="/images/america.jpg" style={{
                  position:'absolute',
                  top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%',
                  borderRadius:'16px', objectFit:'cover'
                }}/>
              </Box>
              <Typography element="p" sx={{textAlign:"left", fontWeight:900, mb:'0px', fontSize:'20px'}}>
                아메리칸 위스키
              </Typography>
              <Typography element="p" sx={{textAlign:"left", mb:'24px', fontSize:'14px'}}>
                미국 (United States)
              </Typography>
            </Grid>
            <Grid item md={3} lg={2}>
              <Box sx={{position:'relative', width:'100%', paddingBottom:'100%', mb:'12px'}}>
                <img src="/images/japan.jpg" style={{
                  position:'absolute',
                  top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%',
                  borderRadius:'16px', objectFit:'cover'
                }}/>
              </Box>
              <Typography element="p" sx={{textAlign:"left", fontWeight:900, mb:'0px', fontSize:'20px'}}>
                재패니즈 위스키
              </Typography>
              <Typography element="p" sx={{textAlign:"left", mb:'24px', fontSize:'14px'}}>
                일본 (Japan)
              </Typography>
            </Grid>
            <Grid item md={3} lg={2}>
              <Box sx={{position:'relative', width:'100%', paddingBottom:'100%', mb:'12px'}}>
                <img src="/images/canada.jpg" style={{
                  position:'absolute',
                  top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%',
                  borderRadius:'16px', objectFit:'cover'
                }}/>
              </Box>
              <Typography element="p" sx={{textAlign:"left", fontWeight:900, mb:'0px', fontSize:'20px'}}>
                캐나디안 위스키
              </Typography>
              <Typography element="p" sx={{textAlign:"left", mb:'24px', fontSize:'14px'}}>
                캐나다 (Canada)
              </Typography>
            </Grid>
            <Grid item md={3} lg={2}>
              <Box sx={{position:'relative', width:'100%', paddingBottom:'100%', mb:'12px'}}>
                <img src="/images/taiwan.jpg" style={{
                  position:'absolute',
                  top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%',
                  borderRadius:'16px', objectFit:'cover'
                }}/>
              </Box>
              <Typography element="p" sx={{textAlign:"left", fontWeight:900, mb:'0px', fontSize:'20px'}}>
                대만 위스키
              </Typography>
              <Typography element="p" sx={{textAlign:"left", mb:'24px', fontSize:'14px'}}>
                대만 (Taiwan)
              </Typography>
            </Grid>
            <Grid item md={3} lg={2}>
              <Box sx={{position:'relative', width:'100%', paddingBottom:'100%', mb:'12px'}}>
                <img src="/images/whiskey2.jpg" style={{
                  position:'absolute',
                  top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%',
                  borderRadius:'16px', objectFit:'cover'
                }}/>
              </Box>
              <Typography element="p" sx={{textAlign:"left", fontWeight:900,mb:'0px', fontSize:'20px'}}>
                기타 위스키
              </Typography>
              <Typography element="p" sx={{textAlign:"left", mb:'24px', fontSize:'14px'}}>
                인도 등
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>*/}
      <Box sx={{position:'relative', width:'100%', height:'560px', boxSizing:'border-box'}}>
        <img src="/images/scotch_whiskey.jpg" style={{
          position:'absolute',
          top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%',
          objectFit:'cover', filter:'brightness(50%)'
        }}/>
        <Stack
          sx={{
            position:'absolute',
            boxSizing:'border-box',
            top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%',
            padding:'36px'
          }}
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}>
          <Typography element="p" sx={{
            fontFamily: 'Gowun Bold',
            textAlign:"center", fontWeight:900,mb:'0px', fontSize:'32px', color:'#ffffff'}}>
            Scotch Whiskey
          </Typography>
          <Typography element="p" sx={{
            fontFamily: 'Gowun Bold',
            textAlign:"center", mb:'24px', fontSize:'18px', color:'#bbbbbb'}}>
            스코틀랜드는 위스키의 발상지로 전 세계적으로 유명합니다. 스코틀랜드 위스키는 그 특유의 풍미와 품질로 인정받고 있으며, 크게 하이랜드(Highland), 스페이사이드(Speyside), 로우랜드(Lowland), 아일라(Islay), 캠벨타운(Campbeltown)의 5개 주요 지역으로 나뉩니다. 각 지역은 고유의 기후와 지형적 특성을 바탕으로 독특한 맛과 향을 지닌 위스키를 생산합니다.
          </Typography>
        </Stack>
      </Box>
      <Box sx={{width:{md : '100%', sm : '100%'}, backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
        <Box sx={{position:'relative', width:'100%'}}>
          <Grid
            container
            spacing={2}
            sx={{width:'100%'}}>
            <MenuItem/>
          </Grid>
        </Box>
      </Box>
      <Footer/>
    </Box>
  );
}

MenuPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default MenuPage;
